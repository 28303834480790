// ckhatton.js

console.log('Sunside Studio (https://sunsidestudio.com): Website created by Jennie Lewis and Christopher Hatton [https://ckhatton.com].');

//Google Analytics
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-36904722-19', 'auto');
//Google Analytics END

$(document).on('turbolinks:load', function(){
  //Google Analytics
  ga('send', 'pageview');
  //Google Analytics END
  
  var owl = $('.programmes .owl-carousel');
  owl.owlCarousel({
    margin: 20,
    responsiveClass:true,
    responsive:{
      0:{
        items:1
      },
      400:{
        items:1
      },
      550:{
        items:2
      },
      750: {
        items:2
      },
      1000: {
        items:2
      },
      1200: {
        items:3
      }
    }
  });
  // Go to the next item
  $('.programmes .next').click(function() {
      owl.trigger('next.owl.carousel');
  });
  // Go to the previous item
  $('.programmes .prev').click(function() {
      // With optional speed parameter
      // Parameters has to be in square bracket '[]'
      owl.trigger('prev.owl.carousel', [300]);
  });

  $('.experiences .owl-carousel').owlCarousel({
    items: 1,
    autoplay: true
  });
  
  resizeElements();
  
  $('#masonry-container').masonry({
    itemSelector: '.item',
    columnWidth: 319,
    gutter: 20,
    horizontalOrder: true
  });
  
  $('.mobile-menu-toggle').click(function() {
    $('.navigation').slideToggle();
  });
  
  $.instagramFeed({
    'username': 'futuresfarm',
    'container': "#instagram-feed",
    'display_profile': false,
    'display_biography': false,
    'display_igtv': false,
    'items': 5,
    'items_per_row': 5,
    'margin': 0
  });
});

$(window).on('resize', function(){
  resizeElements();
  
  if ($(window).width() > 1120) {
    $('.navigation').css('display', '');
  }
});

function resizeElements() {
  $('.motivate .image').height($('.motivate .text').outerHeight());
  
  $('.photo-text .image').each(function(){
    if ($(window).width() > 999) {
      $(this).height($(this).parent().find('.text').outerHeight());
    } else {
      $(this).height($(this).width());
    }
  });
  
  $('.photo-grid .image').each(function(){
    $(this).height($(this).width());
  });
}
